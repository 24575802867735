import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import cms from "../components/cms/cms";
import coralogix from "../images/coralogix.svg";
import cnhindustrial from "../images/cnhindustrial.jpeg";
import iohk from "../images/iohk.png";
import stockly from "../images/stockly.jpeg";
import paidy from "../images/paidy.png";
import vakantieDiscounter from "../images/vakantiediscounter.png";
import zf from "../images/zf.png";

import alex from "../images/alex.webp";
import julien from "../images/julien.jpg";
import archie from "../images/archie.jpg";
import ollie from "../images/ollie.jpg";
import debora from "../images/debora.jpg";
import lotan from "../images/lotan.jpeg";
import louis from "../images/louis.jpeg";
import sjors from "../images/sjors.jpeg";

const formURL = cms.keyword === "Rust" ? "https://forms.gle/q8qxsqpcFH8VCLo8A" : "https://forms.gle/JZiv9qgfCdasDVfN7";

function Hero() {
  return (
    <main className="my-10 mx-auto max-w-screen-xl px-4 sm:my-12 sm:px-6 md:my-16">
      <div className="text-center">
        <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
          Hire
          <br className="xl:hidden" />
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-orange-400 to-red-600"> {cms.keyword} Engineers </span>
          Effortlessly
        </h2>
        <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          The most effective platform to hire the right {cms.keyword} Engineering talent
        </p>
        <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
          <div className="rounded-md shadow">
            <a href={formURL} target="_blank" rel="noopener noreferrer" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
              Start Hiring
            </a>
          </div>
        </div>
      </div>
    </main>
  );
}

function TrustedBy() {
  return (
    <div className="bg-white">
      <div className="max-w-screen-xl mx-auto py-10 px-4 sm:px-6 lg:px-8">
        <p className="text-center text-base leading-6 font-semibold uppercase text-gray-600 tracking-wider">
          Trusted by leading {cms.keyword} companies
        </p>
        <div className="mt-6 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img className="h-12" src={coralogix} alt="Coralogix" />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img className="h-12" src={zf} alt="ZF" />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img className="h-12" src={iohk} alt="IOHK" />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
            <img className="h-12" src={paidy} alt="Paidy" />
          </div>
          <div className="col-span-2 flex justify-center md:col-span-3 lg:col-span-1">
            {cms.keyword === "Scala" ? <img className="h-12" src={cnhindustrial} alt="CNH Industrial" /> : <img className="h-12" src={stockly} alt="Stockly" />}
          </div>
        </div>
      </div>
    </div>
  )
}

function Solutions() {
  return (
    <div className="bg-gray-900">
      <div className="pt-12 px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none">
            Solutions tailored to your {cms.keyword} hiring needs
          </h2>
        </div>
      </div>

      <div className="mt-16 bg-white pb-12 lg:pb-16">
        <div className="relative z-0">
          <div className="absolute inset-0 h-5/6 bg-gray-900 lg:h-2/3"></div>
          <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="relative lg:grid lg:grid-cols-7">
              <div className="mx-auto max-w-md lg:mx-0 lg:max-w-none lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3">
                <div className="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-l-lg">
                  <div className="flex-1 flex flex-col">
                    <div className="bg-white px-6 py-10">
                      <div>
                        <h3 className="text-center text-2xl leading-8 font-medium text-gray-900">
                          Job Posting
                        </h3>
                        <div className="mt-4 flex items-center justify-center">
                          <span className="px-3 flex items-start text-6xl leading-none tracking-tight text-gray-900">
                            <span className="mt-2 mr-2 text-4xl font-medium">
                              $
                            </span>
                            <span className="font-extrabold">
                              299
                            </span>
                          </span>
                          <span className="text-xl leading-7 font-medium text-gray-500">
                            /post
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                      <ul>
                        <li className="flex items-start">
                          <div className="flex-shrink-0">
                            <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                          </div>
                          <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                            Feature your job post on our platform
                          </p>
                        </li>
                        <li className="mt-4 flex items-start">
                          <div className="flex-shrink-0">
                            <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                          </div>
                          <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                            Feature your job post on our social media channels
                          </p>
                        </li>
                        <li className="mt-4 flex items-start">
                          <div className="flex-shrink-0">
                            <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                          </div>
                          <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                            Reach thousands of {cms.keyword} Engineers
                          </p>
                        </li>
                        <li className="mt-4 flex items-start">
                          <div className="flex-shrink-0">
                            <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                          </div>
                          <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                            Pay up front
                          </p>
                        </li>
                      </ul>
                      <div className="mt-8">
                        <div className="rounded-lg shadow-md">
                          <a href="/post-job" target="_blank" rel="noopener noreferrer" className="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base leading-6 font-medium text-red-600 hover:text-red-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150" aria-describedby="job-post">
                            Post Job
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-10 max-w-lg mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4">
                <div className="relative z-10 rounded-lg shadow-xl">
                  <div className="pointer-events-none absolute inset-0 rounded-lg border-2 border-red-600"></div>
                  <div className="absolute inset-x-0 top-0 transform translate-y-px">
                    <div className="flex justify-center transform -translate-y-1/2">
                      <span className="inline-flex rounded-full bg-red-600 px-4 py-1 text-sm leading-5 font-semibold tracking-wider uppercase text-white">
                        Most popular
                      </span>
                    </div>
                  </div>
                  <div className="bg-white rounded-t-lg px-6 pt-12 pb-10">
                    <div>
                      <h3 className="text-center text-3xl leading-9 font-semibold text-gray-900 sm:-mx-6">
                        Hiring Platform
                      </h3>
                      <div className="mt-4 flex items-center justify-center">
                        <span className="px-3 text-6xl leading-none tracking-tight text-gray-900 sm:text-6xl font-extrabold">
                          Pay per Hire
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="border-t-2 border-gray-100 rounded-b-lg pt-10 pb-8 px-6 bg-gray-50 sm:px-10 sm:py-10">
                    <ul>
                      <li className="flex items-start">
                        <div className="flex-shrink-0">

                          <svg className="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                          Reduce time-to-hire and cost-to-hire

                        </p>
                      </li>
                      <li className="mt-4 flex items-start">
                        <div className="flex-shrink-0">

                          <svg className="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                          Partner with the leading {cms.keyword} Hiring experts to hire the right talent for your needs
                        </p>
                      </li>
                      <li className="mt-4 flex items-start">
                        <div className="flex-shrink-0">

                          <svg className="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                          We filter out the noise. You only get introduced to candidates that perfectly match your needs
                        </p>
                      </li>
                      <li className="mt-4 flex items-start">
                        <div className="flex-shrink-0">

                          <svg className="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                          Leverage our platform, social media channels and recruitment networks to reach suitable candidates
                        </p>
                      </li>
                      <li className="mt-4 flex items-start">
                        <div className="flex-shrink-0">

                          <svg className="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                          Reach tens of thousands of {cms.keyword} Engineers
                        </p>
                      </li>
                      <li className="mt-4 flex items-start">
                        <div className="flex-shrink-0">

                          <svg className="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                          No risk. Pay only in case of successful hire. No hire means no fees.
                        </p>
                      </li>
                    </ul>
                    <div className="mt-10">
                      <div className="rounded-lg shadow-md">
                        <a href={formURL} target="_blank" rel="noopener noreferrer" className="block w-full text-center rounded-lg border border-transparent bg-red-600 px-6 py-4 text-xl leading-6 font-medium text-white hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150" aria-describedby="tier-growth">
                          Start Hiring
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-10 mx-auto max-w-md lg:m-0 lg:max-w-none lg:col-start-6 lg:col-end-8 lg:row-start-2 lg:row-end-3">
                <div className="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-r-lg">
                  <div className="flex-1 flex flex-col">
                    <div className="bg-white px-6 py-10">
                      <div>
                        <h3 className="text-center text-2xl leading-8 font-medium text-gray-900">
                          {cms.keyword} Consultancy
                        </h3>
                        <div className="mt-4 flex items-center justify-center">
                          <span className="px-3 flex items-start text-6xl leading-none tracking-tight text-gray-900">
                            <span className="mt-2 mr-2 text-4xl font-extrabold">
                              Pay per Hour
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                      <ul>
                        <li className="flex items-start">
                          <div className="flex-shrink-0">
                            <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                          </div>
                          <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                            Fastest way to onboard {cms.keyword} Engineering talent to your project
                          </p>
                        </li>
                        <li className="mt-4 flex items-start">
                          <div className="flex-shrink-0">
                            <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                          </div>
                          <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                            Choose from a range of {cms.keyword} consultants within your budget
                          </p>
                        </li>
                        <li className="mt-4 flex items-start">
                          <div className="flex-shrink-0">
                            <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                          </div>
                          <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                            Flexibility based on your project's needs
                          </p>
                        </li>
                      </ul>
                      <div className="mt-8">
                        <div className="rounded-lg shadow-md">
                          <a href={formURL} target="_blank" rel="noopener noreferrer" className="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base leading-6 font-medium text-red-600 hover:text-red-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150">
                            Start Hiring
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

function HiringPlatform() {
  return (
    <div className="bg-white">
      <div className="max-w-screen-xl mx-auto pb-12 lg:pb-0 px-4 sm:px-6 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div>
          <h2 className="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl">All-in-one {cms.keyword} Hiring Platform</h2>
          <p className="mt-4 text-xl leading-7 text-gray-500">{cms.siteUrl} provides a complete hiring solution by combining job posting, hiring platform and team extension services.</p>
        </div>
        <div className="mt-12 lg:mt-0 lg:col-span-2">
          <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-2 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
            <div className="flex space-x-3">

              <svg className="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              <div className="space-y-2">
                <dt className="text-lg leading-6 font-medium text-gray-900">The Leading {cms.keyword} Hiring Platform</dt>
                <dd className="flex space-x-3 lg:py-0 lg:pb-4">
                  <span className="text-base leading-6 text-gray-500">Each month we reach tens of thousands of {cms.keyword} Engineers who are actively looking for opportunities. We actively leverage our platform, social media channels and recruitment network to reach the right talent for your hiring needs.</span>
                </dd>
              </div>
            </div>
            <div className="flex space-x-3">

              <svg className="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              <div className="space-y-2">
                <dt className="text-lg leading-6 font-medium text-gray-900">Unique Engineering & Hiring Expertise</dt>
                <dd className="flex space-x-3">
                  <span className="text-base leading-6 text-gray-500">Our team consists of a mix of {cms.keyword === "Scala" ? "Scala " : ""}Engineers and Recruiters. By leveraging this unique experience we can better understand your hiring needs and reduce your time-to-hire and cost-to-hire.</span>
                </dd>
              </div>
            </div>
            <div className="flex space-x-3">

              <svg className="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              <div className="space-y-2">
                <dt className="text-lg leading-6 font-medium text-gray-900">Community Driven</dt>
                <dd className="flex space-x-3">
                  <span className="text-base leading-6 text-gray-500">We are deeply committed to the {cms.keyword} community. We sponsor events and education. We provide free educational content to newcomers and provide free job posts for entry-level positions. Let's work together to grow the {cms.keyword} community!</span>
                </dd>
              </div>
            </div>
            <div className="flex space-x-3">

              <svg className="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              <div className="space-y-2">
                <dt className="text-lg leading-6 font-medium text-gray-900">Employer Branding</dt>
                <dd className="flex space-x-3">
                  <span className="text-base leading-6 text-gray-500">Position your brand in the {cms.keyword} community by putting your brand in front of tens of thousands of {cms.keyword} engineers on our platform. Share your technical blog posts on our blog to reach an even wider audience!</span>
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}

function ShareContactDetails() {
  return (
    <div className="bg-white">
      <div className="max-w-screen-xl mx-auto py-10 px-4 sm:px-6 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
          Want to find out more?
          <br />
          <span className="text-red-600">Get in touch!</span>
        </h2>
        <div className="mt-8 flex lg:flex-shrink-0 lg:mt-0">
          <div className="inline-flex rounded-md shadow">
            <a href={formURL} target="_blank" rel="noopener noreferrer" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
              Contact us
            </a>
          </div>
        </div>
      </div>
    </div>

  );
}

function MeetTheTeam() {
  return (
    <div className="bg-white">
      <div className="mx-auto py-12 px-4 max-w-screen-xl sm:px-6 lg:px-8 lg:py-16">
        <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
          <div className="space-y-5 sm:space-y-4">
            <h2 className="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl">Our Team</h2>
            <p className="text-xl leading-7 text-gray-500">Our team has a unique background and expertise in both {cms.keyword === "Scala" ? "Scala " : ""}Engineering and {cms.keyword} Recruitment.</p>
            <p className="text-xl leading-7 text-gray-500">We leverage this expertise to help you hire the right talent for your needs.</p>
          </div>
          <div className="lg:col-span-2">
            <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8">
              <li>
                <div className="space-y-4">
                  <div className="relative pb-2/3">
                    <img className="absolute object-cover h-full w-full shadow-lg rounded-lg" src={alex} alt="Alex Garella" />
                  </div>
                  <div className="text-lg leading-6 font-medium space-y-1">
                    <h4>Alex Garella</h4>
                    <p className="text-gray-600">Founder / {cms.keyword === "Scala" ? "Scala " : ""}Engineer</p>
                  </div>

                  <ul className="flex space-x-5">
                    <li>
                      <a
                        href={cms.keyword === "Rust" ? "https://twitter.com/rustjobs_dev" : "https://twitter.com/ScalaJobsDev"}
                        target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150">
                        <span className="sr-only">Twitter</span>
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/in/agarella/" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150">
                        <span className="sr-only">LinkedIn</span>
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                          <path fillRule="evenodd" d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clipRule="evenodd" />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div className="space-y-4">
                  <div className="relative pb-2/3">
                    <img className="absolute object-cover h-full w-full shadow-lg rounded-lg" src={julien} alt="Julien Truffaut" />
                  </div>
                  <div className="text-lg leading-6 font-medium space-y-1">
                    <h4>Julien Truffaut</h4>
                    <p className="text-gray-600">Co-founder / {cms.keyword === "Scala" ? "Scala " : ""}Engineer</p>
                  </div>

                  <ul className="flex space-x-5">
                    <li>
                      <a
                        href="https://twitter.com/JulienTruffaut"
                        target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150">
                        <span className="sr-only">Twitter</span>
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/in/julientruffaut/" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150">
                        <span className="sr-only">LinkedIn</span>
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                          <path fillRule="evenodd" d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clipRule="evenodd" />
                        </svg>
                      </a>
                    </li>

                  </ul>
                </div>
              </li>


              <li>
                <div className="space-y-4">
                  <div className="relative pb-2/3">
                    <img className="absolute object-cover h-full w-full shadow-lg rounded-lg" src={archie} alt="Archie Amari" />
                  </div>
                  <div className="text-lg leading-6 font-medium space-y-1">
                    <h4>Archie Amari</h4>
                    <p className="text-gray-600">Head of Talent / {cms.keyword} Recruiter</p>
                  </div>

                  <ul className="flex space-x-5">
                    <li>
                      <a
                        href="https://twitter.com/Archie_Amari"
                        target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150">
                        <span className="sr-only">Twitter</span>
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/in/archie-amari-95a883220/" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150">
                        <span className="sr-only">LinkedIn</span>
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                          <path fillRule="evenodd" d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clipRule="evenodd" />
                        </svg>
                      </a>
                    </li>

                  </ul>
                </div>
              </li>

              <li>
                <div className="space-y-4">
                  <div className="relative pb-2/3">
                    <img className="absolute object-cover h-full w-full shadow-lg rounded-lg" src={ollie} alt="Ollie Morado" />
                  </div>
                  <div className="text-lg leading-6 font-medium space-y-1">
                    <h4>Ollie Morado</h4>
                    <p className="text-gray-600">Talent Manager / {cms.keyword} Recruiter</p>
                  </div>

                  <ul className="flex space-x-5">
                    <li>
                      <a
                        href="https://twitter.com/olliemorado"
                        target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150">
                        <span className="sr-only">Twitter</span>
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/in/ojmorado/" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150">
                        <span className="sr-only">LinkedIn</span>
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                          <path fillRule="evenodd" d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clipRule="evenodd" />
                        </svg>
                      </a>
                    </li>

                  </ul>
                </div>
              </li>

              <li>
                <div className="space-y-4">
                  <div className="relative pb-2/3">
                    <img className="absolute object-cover h-full w-full shadow-lg rounded-lg" src={debora} alt="Debora da Costa" />
                  </div>
                  <div className="text-lg leading-6 font-medium space-y-1">
                    <h4>Debora da Costa</h4>
                    <p className="text-gray-600">Talent Manager / {cms.keyword} Recruiter</p>
                  </div>

                  <ul className="flex space-x-5">
                    <li>
                      <a
                        href="https://x.com/Deboracdc"
                        target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150">
                        <span className="sr-only">Twitter</span>
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/in/debora-da-costa-026a5315a/" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150">
                        <span className="sr-only">LinkedIn</span>
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                          <path fillRule="evenodd" d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clipRule="evenodd" />
                        </svg>
                      </a>
                    </li>

                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  );
}

function Stats() {
  return (
    <div className="bg-gray pt-12">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
            Trusted by over 100 companies globally
          </h2>
          <p className="mt-3 text-xl leading-7 text-gray-500 sm:mt-4">
            We helped over a hundred companies hire {cms.keyword} Engineers all over the world
          </p>
        </div>
      </div>
      <div className="mt-10 pb-12 bg-gray sm:pb-16">
        <div className="relative">
          <div className="absolute inset-0 h-1/2 bg-gray"></div>
          <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto">
              <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                    Companies Assisted
                  </dt>
                  <dd className="order-1 text-5xl leading-none font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-orange-400 to-red-600" aria-describedby="item-1">
                    100+
                  </dd>
                </div>
                <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                    {cms.keyword} Engineers Hired
                  </dt>
                  <dd className="order-1 text-5xl leading-none font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-orange-400 to-red-600">
                    300+
                  </dd>
                </div>
                <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                    {cms.keyword} Engineers reached every month
                  </dt>
                  <dd className="order-1 text-5xl leading-none font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-orange-400 to-red-600">
                    10K+
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Community() {
  return (
    <div className="py-8 bg-gray">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h3 className="text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Community Driven
          </h3>
          <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
            Fostering {cms.keyword} community growth through sponsorships and education
          </p>
        </div>

        <div className="mt-10">
          {cms.keyword === "Scala" ?
            // Scala
            <ul className="md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              <li>
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gradient-to-r from-orange-400 to-red-600 text-white">
                      <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z"></path>
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg leading-6 font-medium text-gray-900">Event Sponsorship</h4>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Proud sponsors of ScalaDays 2023 in Madrid, Scala.io 2023 in Paris and Functional Scala 2022 in London.
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-10 md:mt-0">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gradient-to-r from-orange-400 to-red-600 text-white">
                      <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"></path>
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg leading-6 font-medium text-gray-900">Content Sponsorship</h4>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      We sponsor and promote {cms.keyword} content creators like DevInsideYou on Youtube and many others on our blog section.
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-10 md:mt-0">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gradient-to-r from-orange-400 to-red-600 text-white">
                      <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"></path>
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg leading-6 font-medium text-gray-900">Free Courses</h4>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Free {cms.keyword} course material for entry-level and aspiring {cms.keyword} Engineers.
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-10 md:mt-0">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gradient-to-r from-orange-400 to-red-600 text-white">
                      <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18"></path>
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg leading-6 font-medium text-gray-900">Free Career Advice & Market Insights</h4>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Free career advice and insights into the {cms.keyword} market for both job seekers and hiring companies.
                    </p>
                  </div>
                </div>
              </li>
            </ul> :
            // Rust
            <ul className="md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              <li>
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gradient-to-r from-orange-400 to-red-600 text-white">
                      <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"></path>
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg leading-6 font-medium text-gray-900">Course Sponsorship</h4>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Proud sponsors of the Rust 101 project: an open-source university course.
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-10 md:mt-0">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gradient-to-r from-orange-400 to-red-600 text-white">
                      <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18"></path>
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg leading-6 font-medium text-gray-900">Free Career Advice & Market Insights</h4>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Free career advice and insights into the {cms.keyword} market for both job seekers and hiring companies.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          }
        </div>
      </div>
    </div>

  );
}

function Testimonials() {
  return <section className="bg-gray">
    <div className="mb-12 max-w-screen-xl mx-auto md:grid md:grid-cols-2 px-4 md:px-6 lg:px-8">
      <div className="py-10 px-10 md:flex md:flex-col bg-white rounded-lg shadow-lg">
        <div className="md:flex-shrink-0">
          <img className="h-10" src={coralogix} alt="Coralogix Logo" />
        </div>
        <blockquote className="mt-8 md:flex-grow md:flex md:flex-col">
          <div className="border-l border-gray-200 relative text-lg leading-7 font-medium text-gray-500 md:flex-grow">
            <p height="40" className="relative pl-4">
              Our collaboration with {cms.keyword} Jobs has been exceptional from the outset.
              <br/><br/>
              From our initial interaction with Archie, there was a clear dedication to understanding Coralogix in-depth, from our specific technical needs for challenging positions to ensuring candidates also aligned with our company's core values.
              <br/><br/>
              The diligence and attention to detail were evident.
              <br/><br/>
              {cms.keyword} Jobs efficiently filled intricate roles across Scala, Rust, and Machine Learning.
              <br/><br/>
              Their efforts have substantially contributed to the growth of our global team by bringing in remarkable talent.
            </p>
          </div>
          <footer className="mt-8">
            <div className="flex">
              <div className="flex-shrink-0 inline-flex rounded-full border-2 border-white">
                <img className="h-12 w-12 rounded-full" src={lotan} alt="Lotan Kessel Profile Picture" />
              </div>
              <div className="ml-4">
                <div className="text-base leading-6 font-medium text-gray-600">Lotan Kessel</div>
                <div className="text-base leading-6 font-medium text-gray-500">Talent Acquisition, Coralogix</div>
              </div>
            </div>
          </footer>
        </blockquote>
      </div>
      {cms.keyword === "Rust" ?
        <div className="mt-12 md:mt-0 md:ml-8 py-10 px-10 md:flex md:flex-col bg-white rounded-lg shadow-lg">
          <div className="md:flex-shrink-0">
            <img className="h-10" src={stockly} alt="Stockly Logo" />
          </div>
          <blockquote className="mt-8 md:flex-grow md:flex md:flex-col">
            <div className="border-l border-gray-200 relative text-lg leading-7 font-medium text-gray-500 md:flex-grow">
              <p className="relative pl-4">
                We have been working with RustJobs and directly with Archie to solve a double challenge:
                <br /><br />
                1. finding top-level talents with strong mathematical and academic backgrounds,
                <br /><br />
                2. finding talents experienced in Rust (which is relatively rare compared to other historical programming languages).
                <br /><br />
                Such talents are extremely rare and require a strong understanding of the market.
                <br /><br />
                Archie brought great value to us through his ability to listen to our needs and tailor his research. He was genuinely interested in understanding both the technical positions with their specificities, as well as comprehending the complexity of the French academic system (e.g. preparatory classes, Grandes Ecoles).
                <br /><br />
                Despite the challenge they never gave up, found us great profiles and it was a genuine pleasure to work with RustJobs!
              </p>
            </div>
            <footer className="mt-8">
              <div className="flex">
                <div className="flex-shrink-0 inline-flex rounded-full border-2 border-white">
                  <img className="h-12 w-12 rounded-full" src={louis} alt="Louis Mouquet Profile Picture" />
                </div>
                <div className="ml-4">
                  <div className="text-base leading-6 font-medium text-gray-600">Louis Mouquet</div>
                  <div className="text-base leading-6 font-medium text-gray-500">CEO Associate at Stockly</div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div> : <div className="mt-12 md:mt-0 md:ml-8 py-10 px-10 md:flex md:flex-col bg-white rounded-lg shadow-lg">
          <div className="md:flex-shrink-0">
            <img className="h-10" src={vakantieDiscounter} alt="dé VakantieDiscounter Logo" />
          </div>
          <blockquote className="mt-8 md:flex-grow md:flex md:flex-col">
            <div className="border-l border-gray-200 relative text-lg leading-7 font-medium text-gray-500 md:flex-grow">
              <p className="relative pl-4">
                As a leading online travel company in the Netherlands, sourcing top-tier talent in functional Scala has been a persistent challenge for us. Enter Scala Jobs – a recruitment company that has met this challenge head-on and has become an invaluable partner in our growth journey.
                <br /><br />
                Our technical landscape is defined by the intricacies of functional Scala, a technology crucial to our eCommerce platform. Scala Jobs swiftly demonstrated their expertise by presenting a curated selection of candidates who not only matched our technical needs but also embraced our innovative spirit.
                <br /><br />
                What sets Scala Jobs apart is their commitment to quality over quantity. They grasp our technical positions, presenting candidates tailored to our needs. This approach has streamlined our recruitment process, saving time and ensuring value with every hire.
                <br /><br />
                Yet, it's the personal touch that makes Scala Jobs exceptional. Working with Archie has been a pleasure. His dedication, professionalism, and genuine care make him an indispensable extension of our team.
                <br /><br />
                We wholeheartedly recommend Scala Jobs to any company seeking efficient and effective recruitment solutions. Their expertise and personalized approach have undeniably contributed to our success.
              </p>
            </div>
            <footer className="mt-8">
              <div className="flex">
                <div className="flex-shrink-0 inline-flex rounded-full border-2 border-white">
                  <img className="h-12 w-12 rounded-full" src={sjors} alt="Sjors Grijpink Profile Picture" />
                </div>
                <div className="ml-4">
                  <div className="text-base leading-6 font-medium text-gray-600">Sjors Grijpink</div>
                  <div className="text-base leading-6 font-medium text-gray-500">CTO at dé VakantieDiscounter</div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      }
    </div>
  </section>
}

function HiringSolutions() {
  return (
    <Layout>
      <SEO
        keywords={[`${cms.keyword} Engineering Hiring Platform`, `Hire ${cms.keyword} Engineering Talent`]}
        title={`${cms.keyword} Engineering Hiring Platform`}
      />
      <Hero />
      <TrustedBy />
      <Solutions />
      <HiringPlatform />
      <Stats />
      <Testimonials />
      <MeetTheTeam />
      <Community />
      <ShareContactDetails />
    </Layout>
  );
}

export default HiringSolutions;
